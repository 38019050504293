<template>
  <div id="wiki_snippet_comp">
    <!-- Bootstrap table -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Label</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody v-if="!!getSnippet && !isLoading">
        <tr>
          <td>{{ getSnippet.entityLabel }}</td>
          <td>{{ getSnippet.entityDescription }}</td>
        </tr>
        <!-- {{$store.state.wikiSnippet['P31'].value.type}} -->
        <tr v-for="snippet in getSnippet" :key="snippet.label">
          <td v-if="typeof snippet === 'object'">{{ snippet.label }}</td>
          <td
            v-if="
              typeof snippet === 'object' && snippet.value.type === 'entity'
            "
          >
            <ul type="1">
              <li
                class="link-ref"
                v-for="item in snippet.value.value"
                :key="item.qid"
              >
                <a
                  href="#"
                  @click="referenceFetch({ qid: item.qid, label: item.value })"
                  >{{ item.value }}</a
                >
              </li>
            </ul>
          </td>
          <td
            class="snippet_boundary"
            v-if="typeof snippet === 'object' && snippet.value.type === 'uri'"
          >
            <ul type="1">
              <div v-if="snippet.label.search('image') !== -1">
                <div
                  v-for="item in snippet.value.value"
                  :key="item.value"
                  class="img-snippet"
                >
                  <img class="img-logo" :src="item.value" />
                </div>
              </div>
              <div v-else>
                <!-- {{snippet.value.value}} -->
                <a
                  class="link-static"
                  v-for="item in snippet.value.value"
                  :href="item.value"
                  :key="item.value"
                  >> {{ item.value }}</a
                >
              </div>
            </ul>
          </td>
          <td
            v-if="
              typeof snippet === 'object' &&
              snippet.value.type === 'external-id'
            "
          >
            <ul type="1">
              <a
                class="link-external"
                :href="snippet.value.value.externalURL"
                target="_blank"
                >{{ snippet.value.value.value }}</a
              >
            </ul>
          </td>
          <td
            v-if="typeof snippet === 'object' && snippet.value.type === 'text'"
          >
            {{ snippet.value.value }}
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="!isLoading">
        <tr>
          <td colspan="3">{{ $t("comp_wikiSnippet--notSearched") }}</td>
        </tr>
      </tbody>
      <!-- <breeding-rhombus-spinner class="loader_css"
          :animation-duration="2000"
          :size="60"
          :color="'#0a58ca'"
      /> -->
      <img
        class="loader_css"
        src="@/assets/images/transparent.gif"
        v-if="isLoading"
      />
    </table>
    <div
      class="other_results"
      v-if="$store.state.wikiInformation !== null && !isLoading"
    >
      <p
        @click="referenceFetch({ qid: result.qid, label: result.label })"
        v-for="result in getOtherResults"
        :key="result.qid"
      >
        <strong>{{ result.label }}</strong
        >: {{ result.description }}
      </p>
    </div>
  </div>
</template>

<script>
// import { BreedingRhombusSpinner } from 'epic-spinners';

export default {
  components: {
    // BreedingRhombusSpinner,
  },
  data() {
    return {
      search_term: '',
      isLoading: false,
    };
  },
  created() {
    this.$highway.singleDispatcher('wikiSnippet');
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'SET_LOCALE') {
        const newlocale = mutation.payload;

        const { word, type, qid } = this.$store.state.current_selected_entity;
        this.$store.dispatch('setCurrentThought', {
          qid,
          label: word,
          word,
          type,
          lang: newlocale,
        });
      }
    });
  },
  watch: {},
  computed: {
    getSnippet() {
      return this.$store.state.wikiSnippet;
    },
    getOtherResults() {
      const books = this.$store.state.wikiInformation;
      const results = books.map((item) => ({
        qid: item.id,
        label: item.label,
        description: item.description,
      }));
      return results;
    },
  },
  methods: {
    fetchSnippet() {
      this.isLoading = true;
      this.$store.dispatch('wikiWordFetch', this.search_term).then(() => {
        if (this.$store.state.wikiInformation.length === 0) {
          const oneWord = this.search_term.split(' ')[0];
          this.$store
            .dispatch('wikiSnippet', { word: oneWord, type: 'word' })
            .then(() => {
              this.otherIDS(oneWord);
            });
        } else {
          this.$store
            .dispatch('wikiSnippet', { word: this.search_term, type: 'word' })
            .then(() => {
              this.otherIDS(this.search_term);
            });
        }
      });
    },
    referenceFetch(refObj) {
      console.log({ refObj });
      const wordObj = {
        qid: refObj.qid,
        label: refObj.label,
        word: refObj.label,
        type: 'entity',
        lang: this.$store.state.locale.currentLocale,
      };
      this.$store.dispatch('setCurrentThought', { thoughtObject: wordObj });
      // this.$store.dispatch('wikiSnippet', { qid: refObj.qid, type: 'entity' }).then(() => {
      //   this.otherIDS(refObj.label);
      // });
    },
    otherIDS(word) {
      this.$store.dispatch('wikiWordFetch', word).then(() => {
        this.isLoading = false;
        this.search_term = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes loaderanim {
  0% {
    left: 0%;
  }
  25% {
    left: 10%;
  }
  50% {
    left: 30%;
  }
  100% {
    left: 50%;
  }
}

.link-ref {
  text-decoration: underline;
}

.link-static {
  color: rgb(43, 0, 255);
}

.link-external {
  color: green;
  font-weight: bold;
}

.img-logo {
  width: 200px;
  height: auto;
}

.img-snippet {
  width: 100%;
  height: auto;
}

.snippet_boundary {
  position: relative;
}

#wiki_snippet_comp {
  max-height: 100vh;
  margin: 0 auto;
  padding: 20px 16px 20px 16px;
}
// Media query for mobile responsiveness
@media only screen and (max-width: 1768px) {
  #wiki_snippet_comp {
    width: 100%;
  }
}
.search_form {
  div {
    width: 73%;
  }
  button {
    width: 20%;
  }
}
@keyframes loaderanim {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

.loader_css {
  width: 30%;
  height: auto;
  padding: 30px;
  position: relative;
  color: #0a58ca;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  animation-name: loaderanim;
  animation-duration: 20s;
  // transform: translate(-50%);
}
.custom-btn {
  background-color: #0a58ca !important;
  color: white;
  border-color: #0a58ca !important;
  padding: 0.3rem 0.7rem;
  font-size: 0.8rem;
  &:focus,
  &:hover {
    background-color: #0a58cad7 !important;
    border-color: #0a58cad7 !important;
  }
}
.other_results {
  p {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
.custom-form {
  box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
  border: none;
  padding: 0.6rem 0.75rem;
}
</style>
